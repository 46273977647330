/*
	functions
*/
/* z-index */
$z-layers: (
	'modal':            1000,
	'modaless':          900,
	'gnb':	             800,
	'dropdown':          500,
	'hover':             200,
	'tooltip':           100,
	'default':             1,
	'bottomless-pit': -10000
);

@function z($layer) {
	@if not map-has-key($z-layers, $layer) {
		@warn "No layer found for `#{$layer}` in $z-layers map. Property omitted.";
	}

	@return map-get($z-layers, $layer);
}


/* String Replace */
@function str-replace($string, $search, $replace: "") {
	$index: str-index($string, $search);

	@if $index {
		@return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
	}

	@return $string;
}

/* rem */
@function rem($pixels, $context: 16) {
	@return #{$pixels/$context}rem;
}

@function em($pixels, $context: 16) {
	@return #{$pixels/$context}em;
}



/* custom mixin to replace a defined fill color with another one */
@function svg-url-with-replaced-fill($svg, $fill-to-replace, $new-fill) {
	$replaced-svg: str-replace($svg, $fill-to-replace, $new-fill);
	$replaced-svg-url: url('data:image/svg+xml,#{$replaced-svg}');
	@return $replaced-svg-url;
}
