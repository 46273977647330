@import url('https://fonts.googleapis.com/css?family=Noto+Sans+KR:300,400,500,700');


/* Common Style */
* { margin: 0; padding: 0; }
/* set html5 elements to block */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
    display: block;
}
html, body, div, span, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cite, code, del, dfn, em, font, img, ins, q, s, samp, strike, strong, sub, sup, dl, dt, dd, ol, ul, li, fieldset, form, label, legend { line-height: 1.3em; border: 0 none; }
body { position: relative; min-height: 320px; margin: 0 auto; font-size: 100%; line-height: 1.5em; font-family: $font-base-stack; word-break: normal;
	text-size-adjust: 100%;
	background-color: #dadce2;
}
header, h1 { font-size: 100%; }
html, body { height: 100%; }
caption,legend { position: absolute; top: -9999px; left: -9999px; width:0; height: 0; font-size: 0; overflow: hidden; visibility: hidden; }
caption { display: none; }
img { border: none; vertical-align: top; }
select, input { vertical-align: top; border: 0px; border-radius: 0; background-color: transparent; }
hr { display: none; border: 0 none; }
ol, ul, ul li{ list-style: none; }
em { font-style: normal; }
menu li { list-style: none; }
address { display: inline; font-style: normal; }
button { appearance: none; cursor: pointer; border: 0px; background-color: transparent; border-radius:0; }
table {border-collapse:collapse;border-spacing:0;}
input, textarea, keygen, select, button { font-family: $font-base-stack; }
strong { font-weight: normal; }
dfn { font-style: normal; }
i, b, u, s {
    font-style: inherit;
    font-family: inherit;
    font-weight: inherit;
    text-decoration: none;
}

:focus { outline: 0; }
/* link Style */
a:link, a:visited, a:active, a:focus { text-decoration: none; }
a:hover { text-decoration: none; }

.hidden { display: none; }
.hidden-position { position: absolute; top: -99999px; }

body { background-color: #000; }