.page-intro {
	.layout-wrap { box-sizing: border-box; height: 100vh; width: 100vw; /*background: url('/assets/images/bg_cafe_01.jpg') center center no-repeat; background-size: cover;*/
		display: flex; justify-content: center; align-items: center;
	}

	.bgs { display: block; position: fixed; top: 0; right: 0; bottom: 0; left: 0; z-index: z("default") - 1; height: 100vh; width: 100vw;
		div { position: absolute; top: 0; height: 100vh; width: 100vw; background-position: center center; background-repeat: no-repeat; background-size: cover; }

		.cafe01 { background-image: url('/assets/images/bg_cafe_01.jpg'); }
		.cafe02 { background-image: url('/assets/images/bg_cafe_02.jpg'); }
		.cafe03 { background-image: url('/assets/images/bg_cafe_03.jpg'); }
	}

	.temporary { max-width: 550px;
		@include respond-to(phone) { padding: 0 20px;
		}
	}

	.cafe-name { display: block; margin: 0 auto; max-width: 550px; width: 100%; text-indent: -9999px;
		@include aspect-ratio(550, 70);

		.inner { background-image: svg-url-with-replaced-fill($svg-icon-logo, '%23000000', '%23ffffff'); background-position: center center; background-size: 100%; background-repeat: no-repeat; }
	}

	.cafe-info { display: flex; justify-content: center; align-items: center; margin-top: 30px; padding-top: 20px; text-align: center; border-top: 1px solid #fff;
		.txt { position: relative; display: block; box-sizing: border-box; padding-left: 23px; font-size: rem(16); font-weight: 300; color: #fff;
			&:before { content: attr(data-abbr); display: block; position: absolute; top: 0; left: 0; font-size: rem(20); font-weight: 700; color: #fff; }

			strong { font-weight: 400; }
			+ .txt { margin-left: 25px; }
		}

		@include respond-to(phone) { flex-wrap: wrap; justify-content: flex-start; text-align: left;
			.txt { padding-bottom: 10px;
				+ .txt { margin-left: 0; }
			}
		}
	}
}
